import { React, useState, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import {
  MsalProvider,
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
  useIsAuthenticated,
} from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { msalConfig } from './authConfig';
import { Helmet } from 'react-helmet';
import { snowBackendRequest } from './authConfig';

import Page from './pages/Page';

// import Home from './pages/home/Home';
import Login from './pages/login/Login';
import NoInternet from './pages/nointernet/NoInternet';
import NoMatch from './pages/nomatch/NoMatch';
import Footer from './components/layout/Footer';
import ContactUs from './pages/contactus/ContactUs';
import ApprovalReview from './pages/approvals/review/Review';
import AccessPage from './pages/management/AccessPage/AccessPage';
import ServiceWorkerWrapper from './components/ServiceWorkerWrapper';
import { loginRequest } from './authConfig';
import { FetchUserRole } from './pages/FlowCallAPI';
import { UserContext } from './context/UserContext';
// import NavbarSearch from './components/layout/NewTopBar';
// import TopBar from './components/layout/TopBar';
import { UserContextProvider } from './context/UserContext';
import './styles/App.css';

// const Pages = () => {
//   return (
//     <>

//       <Routes>
//         <Route exact path="/" element={<Login />} />
//         <Route path="/" element={<Page />} />
//         <Route path="/request/:ticketNum" element={<Page />} />
//         <Route path="/review/:ticketNum" element={<Page />} />
//         <Route path="/manage/access" element={<AccessPage />} />
//         <Route path="/contactus" element={<ContactUs />} />
//         <Route element={<NoMatch />} />
//       </Routes>
//     </>
//   );
// };

const Clarity = () => {
  return (
    <>
      <Helmet>
        <script type="text/javascript">
          {`(function (c, l, a, r, i, t, y) {
        c[a] =
          c[a] ||
          function () {
            (c[a].q = c[a].q || []).push(arguments);
          };
        t = l.createElement(r);
        t.async = 1;
        t.src = 'https://www.clarity.ms/tag/' + i;
        y = l.getElementsByTagName(r)[0];
        y.parentNode.insertBefore(t, y);
      })(window, document, 'clarity', 'script', '${process.env.REACT_APP_PROJ_ID}');`}
        </script>
      </Helmet>
    </>
  );
};
export default function App() {
  const msalInstance = new PublicClientApplication(msalConfig);
  const [rotate, setRotate] = useState(false);
  rotate ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  return (
    <MsalProvider instance={msalInstance}>
      <UserContextProvider>
        <div className="App">
          <Clarity />
          <div className="App-header">
            <Router>
              {navigator.onLine && <Page />}
              {!navigator.onLine && <NoInternet />}
            </Router>
          </div>
          <br />
          <Footer />
          <ServiceWorkerWrapper />
        </div>
      </UserContextProvider>
    </MsalProvider>
  );
}
