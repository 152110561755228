import { useState, useEffect } from 'react';
import configData from './Config.js';
//import axios from 'axios';
import axios from './../../../../utils/axios';

export function FetchRegions(country, isDisableFields) {
  const url = configData.fetchRegionsURL;
  const [loading, setLoading] = useState(false);
  const [regions, setRegions] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setRegions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (!isDisableFields && country !== '') getData();
  }, [country]);

  return { loading, regions, getData };
}

export function FetchM1M2(country) {
  const [url, setUrl] = useState(configData.fetchM1M2URL);
  const [loading, setLoading] = useState(false);
  const [m1Vals, setM1Vals] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setM1Vals(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, m1Vals, getData };
}

export function FetchShipToTypes(country) {
  const url = configData.fetchShipToTypesURL;
  const [loading, setLoading] = useState(false);
  const [shipToTypes, setShipToTypes] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setShipToTypes(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, shipToTypes, getData };
}
