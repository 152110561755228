import { useState, useEffect } from 'react';
import configData from './Config.js';
//import axios from 'axios';
import axios from './../../../../utils/axios';

export function FetchDeliveringPlants(country, isDisableFields) {
  const url = configData.fetchDeliveryPlantsURL;
  const [loading, setLoading] = useState(false);
  const [deliveringPlants, setDeliveringPlants] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setDeliveringPlants(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') {
      getData();
    }
  }, [country, isDisableFields]);

  return { loading, deliveringPlants, getData };
}

export function FetchShippingConditions(country, isDisableFields) {
  const url = configData.fetchShippingConditionsURL;
  const [loading, setLoading] = useState(false);
  const [shippingConditions, setShippingConditions] = useState([]);
  var countryNew;

  const getData = async () => {
    setLoading(true);
    console.log(`Country`, countryNew);
    const res = await axios.post(url, { country: countryNew });
    setShippingConditions(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    console.log(country);
    if (country !== '') {
      countryNew = country === 'United Kingdom' ? country : 'Others';
      console.log(countryNew);
      getData();
    }
  }, [country, isDisableFields]);

  return { loading, shippingConditions, getData };
}

// export function FetchShippingConditions(country, isDisableFields) {
//   const url = configData.fetchShippingConditionsURL;
//   const [loading, setLoading] = useState(false);
//   const [shippingConditions, setShippingConditions] = useState([]);
//   var countryNew;

//   const getData = async () => {
//     setLoading(true);
//     console.log(`Country`, countryNew);
//     const res = await axios.post(url, { country: countryNew });
//     setShippingConditions(res.data ?? []);
//     setLoading(false);
//   };

//   useEffect(() => {
//     if (!isDisableFields && country !== '') {
//       countryNew = country === 'United Kingdom' ? country : 'Others';
//       console.log(countryNew);
//       getData();
//     }
//   }, [country, isDisableFields]);

//   return { loading, shippingConditions, getData };
// }

export function FetchPOCSuppliers(country, isDisableFields) {
  const url = configData.fetchPOCSuppliersURL;
  const [loading, setLoading] = useState(false);
  const [pocSuppliers, setPocSuppliers] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setPocSuppliers(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country != '') {
      getData();
    }
  }, [country, isDisableFields]);

  return { loading, pocSuppliers, getData };
}
