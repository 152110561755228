import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../utils/Utils.js';
import axios from '../../utils/axios';
// import axios from '../../utils/axios';

export function FetchAllRequests() {
  const url = urls.fetchAllRequestsURL;
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setResData(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return { loading, resData, getData };
}

export function FetchDraftRequests(requestor) {
  const url = urls.fetchDraftRequestsURL;
  const [loading, setLoading] = useState(false);
  const [draftRequests, setDraftRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setDraftRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, draftRequests, getData };
}

export function FetchSubmittedRequests(requestor, type) {
  const url = urls.fetchSubmittedRequestsURL;
  const [loading, setLoading] = useState(false);
  const [submittedRequests, setSubmittedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor, type: type });
    setSubmittedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, submittedRequests, getData };
}

export function FetchRejectedRequests(requestor) {
  const url = urls.fetchRejectedRequestsURL;
  const [loading, setLoading] = useState(false);
  const [rejectedRequests, setRejectedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setRejectedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, rejectedRequests, getData };
}

export function FetchDuplicateRequests(requestor) {
  const url = urls.fetchDuplicateRequestsURL;
  const [loading, setLoading] = useState(false);
  const [duplicateRequests, setDuplicateRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setDuplicateRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, duplicateRequests, getData };
}

export function FetchCompletedRequests(requestor) {
  const url = urls.fetchCompletedRequestsURL;
  const [loading, setLoading] = useState(false);
  const [completedRequests, setCompletedRequests] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setCompletedRequests(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (requestor !== '') getData();
  }, [requestor]);

  return { loading, completedRequests, getData };
}

export function FetchFilteredData(requestor) {
  const url = urls.fetchTicketDataURL;
  const [loading, setLoading] = useState(false);
  const [filteredData, setfilteredData] = useState([]);

  const loadPosts = async () => {
    setLoading(true);
    const res = await axios.post(url, { requestor: requestor });
    setfilteredData(res.data ?? []);
    setLoading(false);
  };
  useEffect(() => {
    if (requestor !== '') loadPosts();
  }, [requestor]);

  return { loading, filteredData, loadPosts };
}

export function FetchCustomerRequestList() {
  const url = urls.fetchCustomerRequestURL;
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setResData(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return { loading, resData, getData };
}

export function FetchCountryList() {
  const url = urls.fetchCountriesURL;
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setResData(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return { loading, resData, getData };
}

export function FetchCountries() {
  const [url, setUrl] = useState(configData.fetchCountriesURL);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setCountries(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, countries, getData };
}

export function FetchRequestTypes() {
  const [url, setUrl] = useState(configData.fetchRequestTypesURL);
  const [loading, setLoading] = useState(false);
  const [requestType, setRequestType] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setRequestType(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, requestType, getData };
}

export function FetchCustRequestType() {
  const [url, setUrl] = useState(configData.fetchCustomerRequestURL);
  const [loading, setLoading] = useState(false);
  const [custRequest, setCustRequest] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setCustRequest(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, custRequest, getData };
}
