import { useState, useEffect } from 'react';
import configData from './Config.js';
import { urls } from '../../../utils/Utils';
// import axios from 'axios';
import axios from '../../../utils/axios';

export function PostToSharepoint(requestor, title, isSave) {
  const url = urls.postToSharepointCreationURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);
    const mainpage = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
    var shipto = JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
    var payer = JSON.parse(sessionStorage.getItem('createCustomerPayer'));
    var soldto = JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));
    var billto = JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

    if (Object.keys(shipto).length != 0) {
      Object.entries(shipto).forEach(([key, value]) => {
        if (key == 'beesRelevantFlag') {
          shipto[key] = value;
        } else if (key == 'promoNews') {
          shipto[key] = value;
        } else {
          shipto[key] = value.toString().trim();
        }
      });
    }

    if (Object.keys(payer).length != 0) {
      Object.entries(payer).forEach(([key, value]) => {
        if (key === 'creditBlock') {
          payer[key] = value;
        } else {
          payer[key] = value.trim();
        }
      });
    }

    if (Object.keys(soldto).length != 0) {
      Object.entries(soldto).forEach(([key, value]) => {
        if (key == 'promoNews') {
          soldto[key] = value;
        } else {
          soldto[key] = value.trim();
        }
      });
    }

    if (Object.keys(billto).length != 0) {
      Object.entries(billto).forEach(([key, value]) => {
        billto[key] = value.trim();
      });
    }

    try {
      const res = await axios.post(url, {
        requestor: requestor,
        requestType: 'Creation',
        title: title.slice(1),
        mainpage: mainpage,
        shipto: shipto,
        payer: payer,
        soldto: soldto,
        billto: billto,
      });
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(isSave, title);
    if (isSave && title !== '') {
      postData();
    }
  }, [isSave]);

  return { loading, isError, result, postData };
}
