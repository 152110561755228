import { useState, useEffect } from 'react';
import configData from './Config';
import { urls } from '../../utils/Utils';
// import axios from 'axios';
import axios from './../../utils/axios';

export function SaveDraft(requestor, draftTicketNum, isSave, type) {
  const url = urls.saveCreationDraftURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    // var today = new Date();
    // const title = `D${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;

    console.log('Starting save of draft');

    const mainpage = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
    var profilepage = JSON.parse(sessionStorage.getItem('createCustomerProfileData'));

    if (Object.keys(profilepage).length != 0) {
      Object.entries(profilepage).forEach(([key, value]) => {
        profilepage[key] = value.toString().replace(/\s+/g, ' ').trim();
      });
    }

    try {
      const res = await axios.post(url, {
        requestor: requestor,
        requestType: 'Creation',
        title: draftTicketNum,
        mainpage: mainpage,
        profilepage: profilepage,
        type: type,
      });
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(isSave);
    if (isSave) {
      postData();
    }
  }, [isSave]);

  return { loading, isError, result, postData };
}
