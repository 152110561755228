import { useState, useEffect } from 'react';
import configData from './Config';
import { urls } from '../../utils/Utils';
// import axios from 'axios';
import axios from './../../utils/axios';

export function SaveDraft(requestor, draftTicketNum, isSave, type) {
  const url = urls.saveCreationDraftURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});
  const [isError, setIsError] = useState(false);

  const postData = async () => {
    setLoading(true);

    // var today = new Date();
    // const title = `D${today.getDate()}${today.getMonth() + 1}${today.getFullYear()}${today.getHours()}${today.getMinutes()}${today.getSeconds()}`;

    console.log('Starting save of draft');

    const mainpage = JSON.parse(sessionStorage.getItem('createCustomerMainPage'));
    var shipto = JSON.parse(sessionStorage.getItem('createCustomerShipTo'));
    var payer = JSON.parse(sessionStorage.getItem('createCustomerPayer'));
    var soldto = JSON.parse(sessionStorage.getItem('createCustomerSoldTo'));
    var billto = JSON.parse(sessionStorage.getItem('createCustomerBillTo'));

    if (Object.keys(shipto).length != 0) {
      Object.entries(shipto).forEach(([key, value]) => {
        shipto[key] = value.toString().replace(/\s+/g, ' ').trim();
      });
    }

    if (Object.keys(payer).length != 0) {
      Object.entries(payer).forEach(([key, value]) => {
        payer[key] = value.replace(/\s+/g, ' ').trim();
      });
    }

    if (Object.keys(soldto).length != 0) {
      Object.entries(soldto).forEach(([key, value]) => {
        soldto[key] = value.replace(/\s+/g, ' ').trim();
      });
    }

    if (Object.keys(billto).length != 0) {
      Object.entries(billto).forEach(([key, value]) => {
        billto[key] = value.replace(/\s+/g, ' ').trim();
      });
    }

    try {
      const res = await axios.post(url, {
        requestor: requestor,
        requestType: 'Creation',
        title: draftTicketNum,
        mainpage: mainpage,
        shipto: shipto,
        payer: payer,
        soldto: soldto,
        billto: billto,
        type: type,
      });
      setResult(res.data ?? {});
    } catch (error) {
      setIsError(true);
    }
    setLoading(false);
  };

  useEffect(() => {
    console.log(isSave);
    if (isSave) {
      postData();
    }
  }, [isSave]);

  return { loading, isError, result, postData };
}
