import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';

import { Button, ButtonGroup, Row, Col, Modal, Spinner } from 'react-bootstrap';
import Creatable, { makeCreatableSelect } from 'react-select/creatable';
import Select, { components } from 'react-select';
import MandatoryText from '../../../components/MandatoryText';

import { validateFields } from './utils/FormValidation';
import {
  FetchDeliveringPlants,
  FetchShippingConditions,
  FetchPOCSuppliers,
} from './utils/FlowCallAPI';
import { FetchSoldToBapi } from '../utils/FlowCallAPI';
import { useFormFields } from './utils/HooksLib';
import { formTemplate } from '../Data';
import {
  mobileLength,
  mobilePlaceholder,
  telephoneLength,
  telephonePlaceholder,
} from '../../../utils/Utils';

const UpdateCustNameDelivPlantUpdate = (props) => {
  const navigate = useNavigate();

  const [pocSupplierValues, setPocSupplierValues] = useState([]);

  const isAuthenticated = useIsAuthenticated();

  const [translations, setTranslations] = useState(props.translations);

  useEffect(() => {
    // if (!isAuthenticated) navigate('/');
    console.log(isAuthenticated);
  }, [isAuthenticated]);

  const [isDisable, setIsDisable] = useState(false);

  useEffect(() => {
    console.log(inputs);
    if (
      props.draftTicketNum[0] !== 'M' &&
      props.status !== 'Duplicate' &&
      props.status !== 'Rejected'
    ) {
      setIsDisable(true);
    }

    if (inputs.name != '') setIsDisableFields(false);
  }, []);

  const [inputs, setInputs] = useFormFields(props.formTemplate);

  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [linkForward, setLinkForward] = useState('');
  const [isCopying, setIsCopying] = useState(false);
  const [copyClick, setCopyClick] = useState(false);
  const [soldToSap, setSoldToSap] = useState(inputs.existingSoldToID);

  const [isDisableFields, setIsDisableFields] = useState(true);

  const mainPageForm = JSON.parse(sessionStorage.getItem('updateCustomerMainPage')) || {};

  const deliveringPlantsRes = FetchDeliveringPlants(mainPageForm.country, isDisableFields);

  const pocSuppliersRes = FetchPOCSuppliers(mainPageForm.country, isDisableFields);

  const shippingConditionsRes = FetchShippingConditions(mainPageForm.country, isDisableFields);

  const soldToDataRes = FetchSoldToBapi(
    inputs.existingSoldToID,
    mainPageForm.companyCode,
    mainPageForm.salesOrg,
    isCopying,
  );

  const handleSubmit = (e) => {
    console.log('Submitting...');
    if (e) e.preventDefault();
    if (e.target.getAttribute('data-back') === 'true')
      props.setActivePage(e.target.getAttribute('data-link'));
    else {
      setLinkForward(e.target.getAttribute('data-link'));
      setErrors(validateFields(inputs, false, soldToSap));
      setIsSubmitting(true);
    }
  };

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      props.setActivePage(linkForward);
    }
  }, [errors]);

  function getLang(id) {
    // console.log(props.translations);
    let obj = translations.find((o) => o.textID === id);
    // console.log(obj);
    if (!obj) {
      return id;
    } else {
      return obj.localizedText;
    }
  }

  useEffect(() => {
    setTranslations(props.translations);
  }, [props.translations]);

  function copySoldToData(e) {
    setCopyClick(true);
    setErrors(validateFields(inputs, true, soldToSap));
    console.log(`I am true ${isCopying}`);
  }

  useEffect(() => {
    console.log(`${JSON.stringify(errors)} ${isSubmitting}`);
    if (Object.keys(errors).length === 0 && isSubmitting) {
      console.log('Can go forward');
      props.setActivePage(linkForward);
    } else if (Object.keys(errors).length === 0 && copyClick) {
      console.log(inputs.name, isDisableFields, copyClick);
      setCopyClick(false);
      setIsCopying(true);
      Object.entries(formTemplate.nameDelivPlantUpdate).forEach(([key, value]) => {
        if (key !== 'existingSoldToID') inputs[key] = value;
      });
    }
  }, [errors]);

  useEffect(() => {
    if (Object.keys(soldToDataRes.soldToData).length !== 0 && isCopying) {
      if (inputs.existingSoldToID != '') {
        setSoldToSap(inputs.existingSoldToID);
        console.log(`Setting sold to num: ${soldToSap}`);
      }
      Object.entries(soldToDataRes.soldToData).forEach(([key, value]) => {
        if (key in inputs) {
          // if (key === 'telephone' && value !== '') inputs.telephoneOption = 'General/Accounting';

          inputs[key] = value;

          if (key === 'deliveringPlant') {
            if (deliveringPlantsRes.deliveringPlants.length != 0) {
              if (inputs.deliveringPlant != '') {
                let obj = deliveringPlantsRes.deliveringPlants.find(
                  (o) => o.plantCode === inputs.deliveringPlant,
                );
                // console.log('I AM IN REGION')
                // console.log(obj)
                if (obj) {
                  console.log(`Plant Desc = ${obj.plantDesc}`);
                  inputs.deliveringPlantDesc = `${obj.plantCode} - ${obj.plantDesc}`;
                }
              }
            }
          } else if (key == 'shippingCondition') {
            if (shippingConditionsRes.shippingConditions.length != 0) {
              if (inputs.shippingCondition != '') {
                let obj = shippingConditionsRes.shippingConditions.find(
                  (o) => o.shippingConditionCode === inputs.shippingCondition,
                );
                if (obj) {
                  console.log(`Shipping Desc = ${obj.shippingCondition}`);
                  inputs.shippingConditionDesc = `${obj.shippingConditionCode} - ${obj.shippingCondition}`;
                }
              }
            }
          } else if (key == 'pocSupplierShipTo') {
            inputs.pocSupplier = value;
            if (pocSuppliersRes.pocSuppliers.length != 0) {
              console.log('setting');
              if (inputs.pocSupplier != '') {
                console.log('setting again');
                let obj = pocSuppliersRes.pocSuppliers.find(
                  (o) => o.pocNumber === inputs.pocSupplier,
                );
                if (obj) {
                  inputs.pocSupplierDesc = `${obj.pocNumber} - ${obj.pocName}`;
                }
              }
            }
          }
        }
      });
      sessionStorage.setItem('updateCustomerNameDelivPlantUpdate', JSON.stringify(inputs));
      setIsDisableFields(false);
    }
    setIsCopying(false);
  }, [soldToDataRes.soldToData]);

  // useEffect(() => {

  // }, [pocSuppliersRes.pocSuppliers])

  const Input = (props) => <components.Input {...props} maxLength={8} />;

  const pocOptions = pocSuppliersRes.pocSuppliers.map((val) => {
    return {
      ...val,
      pocNumber: val.pocNumber,
      pocName: val.pocName,
      pocPayer: val.pocPayer,
      pocPriceListERP: val.pocPriceListERP,
      pocDesc: `${val.pocNumber} - ${val.pocName}`,
    };
  });

  return (
    <form className="card" onSubmit={handleSubmit}>
      <Modal show={isCopying} centered>
        <Modal.Body>
          <Row>
            <div className="col-auto">
              <Spinner animation="border" variant="warning" />
            </div>
            <div className="text-left">
              <span className="align-middle">&nbsp;Please wait while we fetch your data</span>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
      <div className="card-header">
        <div className="col-12">
          <h3 className="card-title text-left">
            <strong>Customer Data {getLang('Update')}</strong>
          </h3>
        </div>
      </div>
      <Row className="sidebar-row">
        <div className="col-12">
          <div className="card-body">
            <Row>
              <Col sm={12} lg="auto">
                <h2 style={{ color: 'rgb(177, 31, 36)' }}>
                  {getLang('Ship-to & Sold To Modification')}
                </h2>
              </Col>
            </Row>
            <MandatoryText text={getLang(`Fields marked with an asterisk (*) are required`)} />

            <div className="row">
              <div className="col-lg-12">
                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Sold To ID')} *
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingSoldToID ? ' is-invalid' : ''}`}
                          name="existingSoldToID"
                          maxLength="8"
                          placeholder={`Enter ${getLang('Existing Sold To ID')}`}
                          value={inputs.existingSoldToID}
                          onChange={setInputs}
                          disabled={isDisable}
                        />
                        <span className="input-group-append">
                          <button
                            className="btn btn-primary"
                            type="button"
                            style={{
                              borderTopRightRadius: '3px',
                              borderBottomRightRadius: '3px',
                            }}
                            onClick={copySoldToData}
                            disabled={isDisable || inputs.existingSoldToID == ''}
                          >
                            {getLang('Copy')}
                          </button>
                        </span>
                        {errors.existingSoldToID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingSoldToID}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Existing Ship-to ID')}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control${errors.existingShipToID ? ' is-invalid' : ''}`}
                          name="existingShipToID"
                          maxLength="8"
                          value={inputs.existingShipToID}
                          onChange={setInputs}
                          disabled
                        />
                        {errors.existingShipToID && (
                          <div className="invalid-feedback text-left">
                            {errors.existingShipToID}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <hr />

                {['France', 'Belgium'].includes(mainPageForm.country) &&
                  !mainPageForm?.shipToType == 'Wholesaler' && (
                    <>
                      <Row>
                        <Col>
                          <h5 className="text-left color-text">
                            {getLang(
                              'The Name, Email, Telephone and Mobile will not be adapted on Ship-To Level. It will be adapted only on Sold-To Level',
                            )}
                          </h5>
                        </Col>
                      </Row>

                      <br />
                    </>
                  )}

                <div className="row">
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Name')}</label>
                      <input
                        type="text"
                        className={`form-control ${errors.name ? 'is-invalid' : ''}`}
                        name="name"
                        placeholder={`${getLang('Name')}`}
                        value={inputs.name}
                        onChange={setInputs}
                        maxLength="35"
                        disabled={isDisableFields || isDisable}
                      />
                      {errors.name && (
                        <div className="invalid-feedback text-left">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Delivering Plant')}</label>
                      <select
                        className="form-control"
                        name="deliveringPlant"
                        value={inputs.deliveringPlant}
                        disabled={
                          inputs.name == '' || isDisable || mainPageForm.country == 'Netherlands'
                        }
                        onChange={setInputs}
                      >
                        <option value="">Select</option>
                        {mainPageForm.country == 'France' &&
                          deliveringPlantsRes.deliveringPlants
                            .filter((e) =>
                              ['FR06', 'FR07', 'FR08', 'FR10', 'FR11'].includes(e.plantCode),
                            )
                            .map((val) => (
                              <option
                                key={val.plantCode}
                                value={val.plantCode}
                                data-plant={`${val.plantCode} - ${val.plantDesc}`}
                              >{`${val.plantCode} - ${val.plantDesc}`}</option>
                            ))}
                        {!['Belgium', 'France'].includes(mainPageForm.country) &&
                          deliveringPlantsRes.deliveringPlants.map((val) => (
                            <option
                              key={val.plantCode}
                              value={val.plantCode}
                              data-plant={`${val.plantCode} - ${val.plantDesc}`}
                            >{`${val.plantCode} - ${val.plantDesc}`}</option>
                          ))}
                        {mainPageForm.country == 'Belgium' &&
                          mainPageForm.shipToType.includes('Wholesaler') &&
                          deliveringPlantsRes.deliveringPlants.map(
                            (val) =>
                              val.plantCode != 'BE15' && (
                                <option
                                  key={val.plantCode}
                                  value={val.plantCode}
                                  data-plant={`${val.plantCode} - ${val.plantDesc}`}
                                >{`${val.plantCode} - ${val.plantDesc}`}</option>
                              ),
                          )}
                      </select>
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Country')}</label>
                      <input
                        type="text"
                        className="form-control"
                        value={mainPageForm.country}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Telephone')}</label>
                      <input
                        type="tel"
                        placeholder={telephonePlaceholder(mainPageForm.country)}
                        className={`form-control ${errors.telephone ? 'is-invalid' : ''}`}
                        name="telephone"
                        maxLength={telephoneLength(mainPageForm.country)}
                        value={inputs.telephone}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.telephone && (
                        <div className="invalid-feedback text-left">{errors.telephone}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('Mobile')}</label>
                      <input
                        type="tel"
                        placeholder={mobilePlaceholder(mainPageForm.country)}
                        className={`form-control ${errors.mobile ? 'is-invalid' : ''}`}
                        maxLength={mobileLength(mainPageForm.country)}
                        name="mobile"
                        value={inputs.mobile}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.mobile && (
                        <div className="invalid-feedback text-left">{errors.mobile}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('E-mail Address')}</label>
                      <input
                        type="email"
                        className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                        name="email"
                        placeholder="Eg.: abc@company.com"
                        value={inputs.email}
                        onChange={setInputs}
                        maxLength="240"
                        disabled={inputs.name == '' || isDisable}
                      />
                      {errors.email && (
                        <div className="invalid-feedback text-left">{errors.email}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">
                        {getLang('Shipping Condition')}
                      </label>
                      <select
                        className="form-control"
                        name="shippingCondition"
                        value={inputs.shippingCondition}
                        onChange={setInputs}
                        disabled={inputs.name == '' || isDisable}
                      >
                        <option value="">Select</option>
                        {mainPageForm.country == 'Belgium' &&
                          shippingConditionsRes.shippingConditions
                            .filter((e) => ['T1', 'T2', 'TJ'].includes(e.shippingConditionCode))
                            .map((val) => (
                              <option
                                key={val.shippingConditionCode}
                                value={val.shippingConditionCode}
                                data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                              >
                                {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                              </option>
                            ))}
                        {mainPageForm.country == 'Netherlands' &&
                          shippingConditionsRes.shippingConditions
                            .filter((e) => ['T1', 'TJ'].includes(e.shippingConditionCode))
                            .map((val) => (
                              <option
                                key={val.shippingConditionCode}
                                value={val.shippingConditionCode}
                                data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                              >
                                {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                              </option>
                            ))}
                        {mainPageForm.country == 'France' &&
                          shippingConditionsRes.shippingConditions
                            .filter((e) => ['T1', 'T2'].includes(e.shippingConditionCode))
                            .map((val) => (
                              <option
                                key={val.shippingConditionCode}
                                value={val.shippingConditionCode}
                                data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                              >
                                {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                              </option>
                            ))}
                        {!['Belgium', 'France', 'Netherlands'].includes(mainPageForm.country) &&
                          shippingConditionsRes.shippingConditions.map((val) => (
                            <option
                              key={val.shippingConditionCode}
                              value={val.shippingConditionCode}
                              data-shipcondn={`${val.shippingConditionCode} - ${val.shippingCondition}`}
                            >
                              {`${val.shippingConditionCode} - ${val.shippingCondition}`}
                            </option>
                          ))}
                      </select>
                      {errors.shippingCondition && (
                        <div className="invalid-feedback text-left">{errors.shippingCondition}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <div className="form-group">
                      <label className="form-label text-left">{getLang('POC Supplier')}</label>
                      <Select
                        className="text-left"
                        name="pocSupplier"
                        value={pocOptions.filter((opt) => opt.pocNumber == inputs.pocSupplier)}
                        components={{ Input }}
                        theme={(theme) => ({
                          ...theme,
                          colors: {
                            ...theme.colors,
                            primary25: '#cfcfcf',
                            primary50: '#cfcfcf',
                            primary: 'orange',
                          },
                        })}
                        isClearable
                        placeholder="Eg: 12345678"
                        isDisabled={inputs.name == '' || isDisable}
                        getOptionLabel={({ pocDesc }) => pocDesc}
                        getOptionValue={({ pocNumber }) => pocNumber}
                        options={pocOptions}
                        onChange={(e, action) => {
                          setInputs(e, action);
                        }}
                      />

                      {errors.pocSupplier && (
                        <div className="invalid-feedback-file text-left">{errors.pocSupplier}</div>
                      )}
                    </div>
                  </div>
                  {mainPageForm.country != 'Netherlands' && (
                    <div className="col-12 col-lg-3">
                      <div className="form-group">
                        <label className="form-label text-left">
                          {getLang('If POC Supplier not in list please specify here')}
                        </label>
                        <input
                          type="text"
                          className={`form-control ${errors.pocSupplier ? 'is-invalid' : ''}`}
                          name="pocSupplier"
                          maxLength="8"
                          value={inputs.pocSupplier}
                          onChange={setInputs}
                          disabled={inputs.name == '' || isDisable}
                        />
                        {errors.pocSupplier && (
                          <div className="invalid-feedback text-left">{errors.pocSupplier}</div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <br />

      <div className="card-footer">
        <div className="row">
          {!isDisable && (
            <div className="col-auto col-md-auto text-left">
              <Button variant="outline-warning" onClick={props.saveDraft}>
                Save Draft
              </Button>
            </div>
          )}
          <div className="col col-md text-left">
            <span className="align-middle">{props.draftMsg}</span>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="mainPage"
              data-back="true"
              className="btn btn-warning btn-block"
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Back
            </Link>
          </div>
          <div className="col-auto col-md-auto text-right">
            <Link
              data-link="nameDelivPlantUpdateReview"
              className={`btn btn-danger btn-block${
                inputs.existingSoldToID == '' || inputs.name == '' ? ' disabled' : ''
              }`}
              style={{ textDecoration: 'none' }}
              onClick={handleSubmit}
            >
              Review
            </Link>
          </div>
        </div>
      </div>
    </form>
  );
};

export default UpdateCustNameDelivPlantUpdate;
