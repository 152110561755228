import { useState, useEffect } from 'react';
import configData from './Config.js';
//import axios from 'axios';
import axios from './../../../../utils/axios';

// export function FetchCountries() {
//     const [countries, setCountries] = useState([]);
//     const [url, setUrl] = useState(configData.fetchCountriesURL);

//     const [isLoading, setIsLoading] = useState(false);
//     const [isError, setIsError] = useState(false);

//     useEffect(() => {
//         const fetchData = async () => {
//             setIsError(false);
//             setIsLoading(true);

//             try {
//                 const res = await axios.post(url);

//                 setCountries(res.data);
//             } catch (error) {
//                 setIsError(true);
//             }

//             setIsLoading(false);
//         };

//         fetchData();
//     }, [url]);

//     return countries;
// }

export function FetchCountries() {
  const [url, setUrl] = useState(configData.fetchCountriesURL);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url);
    setCountries(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, [url]);

  return { loading, countries, getData };
}

export function FetchM1M2(country) {
  const [url, setUrl] = useState(configData.fetchM1M2URL);
  const [loading, setLoading] = useState(false);
  const [m1Vals, setM1Vals] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setM1Vals(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, m1Vals, getData };
}

export function FetchCustomerReqTypes(country) {
  const [url, setUrl] = useState(configData.fetchCustReqTypes);
  const [loading, setLoading] = useState(false);
  const [reqTypes, setReqTypes] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setReqTypes(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, reqTypes, getData };
}

export function FetchShipToTypes(country) {
  const url = configData.fetchShipToTypes;
  const [loading, setLoading] = useState(false);
  const [shipToTypes, setShipToTypes] = useState([]);

  const getData = async () => {
    setLoading(true);
    const res = await axios.post(url, { country: country });
    setShipToTypes(res.data ?? []);
    setLoading(false);
  };

  useEffect(() => {
    if (country !== '') getData();
  }, [country]);

  return { loading, shipToTypes, getData };
}

export function SaveDraft(requestor, title, inputs, isSave) {
  const url = configData.saveDraftURL;
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState({});

  const postData = async () => {
    setLoading(true);
    const res = await axios.post(url, {
      requestor: requestor,
      requestType: 'Creation',
      title: title,
      mainpage: inputs,
    });
    setResult(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    if (isSave && title !== '') postData();
  }, [isSave]);

  return { loading, result, postData };
}
