import { useState, useEffect } from 'react';
import configData from './Config';
import { urls } from '../../../utils/Utils';
import axios from 'axios';
//import axios from '../../../utils/axios';

export function FetchBillToBapi(billToID, companyCode, salesOrg, isCopying) {
  const url = urls.fetchBillToBapiURL;
  const [loading, setLoading] = useState(false);
  const [billToData, setBillToData] = useState({});

  const getData = async () => {
    setLoading(true);
    const res = await axios.get(url, {
      params: { billToID: billToID, companyCode: companyCode, salesOrg: salesOrg },
    });
    setBillToData(res.data ?? {});
    setLoading(false);
  };

  useEffect(() => {
    if (isCopying && billToID !== '' && companyCode !== '' && salesOrg !== '') {
      console.log('hello');
      getData();
    }
  }, [billToID, companyCode, salesOrg, isCopying]);

  return { loading, billToData, getData };
}
