import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import CreateCustomer from './createcustomer/CreateCustomer';
import CustomerExtension from './customerExtension/CustomerExtension';
import CreateInternal from './createInternalCustomer/CreateInternalCustomer';
import CreateHierarchy from './createHierarchy/CreateHierarchy';
import UpdateCustomer from './updatecustomer/UpdateCustomer';
import CreateNLCustomer from './createNLcustomer/CreateNLCustomer';
import CreateInterCompanyCustomer from './createInterCompCustomer/CreateInterCompCustomer';
import CreateVendorCustomer from './createVendorcustomer/CreateVendorCustomer';
import CreateForward from './createForwardAgeiingCustomer/CreateForward';
import Home from './home/Home';
import AllTickets from './AllTickets/AllTicket';
import NoAccess from './noaccess/NoAccess';
import Approvals from './approvals/Approvals';
import ApprovalReview from './approvals/review/Review';
import TopBar from '../components/layout/TopBar';
import AccessPage from './management/AccessPage/AccessPage';
import ContactUs from './contactus/ContactUs';
import Login from './login/Login';
import { Modal, Spinner, Row, Col } from 'react-bootstrap';

// Msal imports
import { MsalAuthenticationTemplate, useMsal, useAccount } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../authConfig';

import {
  FetchTicketData,
  FetchTranslations,
  FetchCreateAccess,
  FetchModAccess,
  FetchApprovalAccess,
  FetchReviewTicketData,
  FetchAdminAccess,
  FetchAllRequestsView,
} from './FlowCallAPI';
import ConfirmationModal from '../components/ConfirmationModal';
import UpdateModal from '../components/UpdateModal';
import { UserContext } from '../context/UserContext';
import CreatePlant from './plantcreation/CreatePlant';

const Page = (props) => {
  const authRequest = {
    ...loginRequest,
  };

  // let { params } = props.match;

  let { location } = useLocation();

  let { ticketNum } = useParams();
  console.log('UseParams ', ticketNum);

  const { user } = useContext(UserContext);

  // console.log(`It is : ${process.env.REACT_APP_ENV}`);
  console.log('user', user);
  // console.log('PROPS', props);

  // console.log(params);

  const navigate = useNavigate();
  const [rotate, setRotate] = useState(false);
  useEffect(() => {
    console.log('id_token', 'id_token' in localStorage);
    console.log('access_token', 'access_token' in localStorage);
    console.log('token-customscope', 'token-customscope' in localStorage);
    if ('id_token' in localStorage) {
      localStorage.removeItem('id_token');
    }
    if ('access_token' in localStorage) {
      localStorage.removeItem('access_token');
    }
    if ('token-customscope' in localStorage) {
      localStorage.removeItem('token-customscope');
    }
  }, []);
  rotate ? (document.body.style.overflow = 'hidden') : (document.body.style.overflow = 'auto');
  // const location = useLocation();

  const [lang, setLang] = useState(localStorage.getItem('appLang') || 'en');

  const [ticket, setTicket] = useState();
  const [opened, setOpened] = useState(true);
  const [status, setStatus] = useState('');
  const [ticketReqType, setTicketReqType] = useState('');
  const [creationType, setCreationType] = useState('');
  const [customerRequest, setCustomerRequest] = useState('');
  const [reqType, setReqType] = useState('');
  const [token, setToken] = useState('');

  const isAuthenticated = useIsAuthenticated();

  const [isFetch, setIsFetch] = useState(false);

  const [activePage, setActivePage] = useState('');

  const [clear, setClear] = useState(false);

  const { instance, accounts } = useMsal();

  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const [showModal, setShowModal] = useState(false);

  const [confirmationModal, setConfirmationModal] = useState('');

  const [confirmationId, setConfirmationId] = useState('');

  const [CrData, setCrData] = useState(0);

  const [approvalData, setApprovalData] = useState([]);

  const fetchCreateAccessRes = FetchCreateAccess(accounts.length > 0 ? accounts[0].username : '');

  const fetchModAccessRes = FetchModAccess(accounts.length > 0 ? accounts[0].username : '');

  const fetchApprovalAccessRes = FetchApprovalAccess(
    accounts.length > 0 ? accounts[0].username : '',
  );

  const fetchAdminAccessRes = FetchAdminAccess(accounts.length > 0 ? accounts[0].username : '');

  console.log(ticketNum, 'ticketNum', ticket);
  useEffect(() => {
    if (localStorage.getItem('Role') !== 'undefined' && ticketNum == null) {
      console.log('Settin home');
      setActivePage('home');
    } else {
      setActivePage('');
    }
  }, [fetchCreateAccessRes.result, fetchModAccessRes.result]);

  const changeLang = (e) => {
    console.log(`Language: ${e.target.value}`);
    localStorage.setItem('appLang', e.target.value);
    setLang(e.target.value);
  };

  const openRequest = (ticketNum, requestType, creationType, customerRequest, reqType) => {
    if (ticketNum !== '') {
      console.log(`Opening: ${ticketNum} ${requestType} ${creationType} ${customerRequest}`);
      setIsFetch(true);
      setTicket(ticketNum);
      setTicketReqType(requestType);
      setShowModal(true);
      // setStatus('Duplicate');
      setCreationType(creationType);
      setCustomerRequest(customerRequest);
      setReqType(customerRequest);
      setActivePage('create');
      console.log('Customers', requestType);
      console.log('from openrequest: ', fetchTicketDataRes.result.mainpage);
      // if (Object.keys(fetchTicketDataRes.result).length !== 0)
      //   if (requestType === 'Creation') {
      //     if (customerRequest === 'Full Creation') navigate('/create/general');
      //     else if (customerRequest === 'Hierarchy') navigate('/create/hierarchy');
      //     else if (customerRequest === 'Internal Customer') navigate('/create/internal');
      //     else if (customerRequest === 'Vendor Customer') navigate('/create/vendor');
      //     else navigate('/create/general');
      //   } else if (requestType === 'Plant') navigate('/create/plant');
      //   else if (requestType === 'Modification') navigate('/modify');
      // console.log('redirecting');
    }
  };

  const AllRequestViewData = FetchAllRequestsView(ticket);
  const RequestCreationType = (ticketNum, creationType, customerRequest, reqType) => {
    if (ticketNum !== '') {
      console.log(`Opening1: ${ticketNum} ${creationType} ${customerRequest} ${reqType}`);
      setTicket(ticketNum);
      setCreationType(creationType);
      setCustomerRequest(customerRequest);
      setReqType(reqType);

      // setActivePage('create');
    }
  };
  var requestObj = {
    scopes: ['api://b8f281cd-6a4b-4673-9e89-81ebf43d3882/access_as_user'],
  };
  // useEffect(() => {
  //   console.log('get access token');
  //   console.log('account user name  ' + accounts[0].username);
  //   if (accounts) {
  //     instance
  //       .acquireTokenSilent({
  //         scopes: ['api://b8f281cd-6a4b-4673-9e89-81ebf43d3882/access_as_user'],
  //         account: accounts[0],
  //       })
  //       .then((response) => {
  //         if (response) {
  //           setToken(response.accessToken);
  //           localStorage.setItem('token-customscope', response.accessToken);
  //         }
  //       })
  //       .catch((error) => {
  //         console.log('error in custom', error);
  //       });
  //   }
  // }, []);
  useEffect(() => {
    window.addEventListener(
      'resize',
      () => {
        const ismobile = window.innerWidth < 992;
        if (ismobile !== isMobile) setIsMobile(ismobile);
      },
      false,
    );
  }, [isMobile]);
  console.log('FTECH TICKET DATA Creation Type', creationType, customerRequest);

  const fetchTicketDataRes = FetchTicketData(
    accounts.length > 0 ? accounts[0].username : '',
    ticket == null ? '' : ticket,
    ticketReqType,
    isFetch,
    status,
    creationType,
    customerRequest,
    reqType,
  );

  const fetchReviewTicketDataRes = FetchReviewTicketData(
    accounts.length > 0 ? accounts[0].username : '',
    ticket,
    ticket != null,
    creationType,
    customerRequest,
    reqType,
  );

  console.log('review tickt datae', fetchReviewTicketDataRes);
  console.log('reType', reqType);
  console.log(
    'approval review view condition',
    ticketNum != null,
    activePage !== 'approvals',
    true || Object.keys(fetchReviewTicketDataRes.result).length != 0,
  );
  useEffect(() => {
    console.log('this ran for review');
    setShowModal(false);
    setIsFetch(false);
    console.log('for tick', fetchTicketDataRes.result);
    if (Object.keys(fetchTicketDataRes.result).length !== 0) {
      if (ticketReqType === 'Creation') {
        if (customerRequest === 'Full Creation') navigate('/create/general');
        else if (customerRequest === 'Hierarchy') navigate('/create/hierarchy');
        else if (customerRequest === 'Internal Customer') navigate('/create/internal');
        else if (customerRequest === 'Vendor Customer') navigate('/create/vendor');
        else navigate('/create/general');
      } else if (ticketReqType === 'Plant') navigate('/create/plant');
      else if (ticketReqType === 'Modification') navigate('/modify');
      // setClear(false);
      //console.log(fetchTicketDataRes.result);
      // if (ticketReqType == 'Creation') setActivePage('create');
      if (customerRequest == 'Vendor Customer') setActivePage('vendor');
      else if (customerRequest == 'Internal Customer') setActivePage('internal');
      else if (customerRequest == 'Inter Company Customer') setActivePage('intercompany');
      else if (customerRequest == 'NL Customer') setActivePage('NL create');
      else if (customerRequest == 'Forward Agent Customer') setActivePage('forwardagent');
      else if (customerRequest == 'Customer Extension') setActivePage('extension');
      // else if (ticketReqType == 'Creation') setActivePage('create');
      else if (['Modification', 'Block/Unblock'].includes(ticketReqType)) setActivePage('update');
      else if (ticketReqType == 'Hierarchy') setActivePage('hierarchy');
      else if (customerRequest == 'Plant Creation') setActivePage('plant');
      else setActivePage('create');
    }
  }, [fetchTicketDataRes.result]);

  const fetchTranslationsRes = FetchTranslations(lang);

  useEffect(() => {}, [fetchTranslationsRes.result]);

  const data = {
    Payer_Name: 'THIJMO B.V.',
    Payer_HouseNumber: '78',
    Payer_Street: 'Vuurlijn',
    Payer_Country: 'Netherlands',
    Payer_PostCode: "'1424 NS",
    Payer_YearlyVolumeForecast: '',
    Payer_TermsOfPayment: '000A',
    Payer_VAT: 'NL814111270B01',
    Payer_CompanyNum: '34219306',
    Rental: 'NO',
    ShipToType: 'On-Trade – Indirect',
    MainRequest1: 'Jelmer Boer ',
    Requestor_EmailID: 'jelmer.boer@interbrew.net',
  };

  const onApprove = (id) => {
    setConfirmationId(id);
    setConfirmationModal('approve');
  };
  const onReject = (id) => {
    setConfirmationId(id);
    setConfirmationModal('reject');
  };
  // console.log('SetCrData', CrData);
  // console.log('TicketNUm', ticketNum);
  // console.log('Access', fetchCreateAccessRes.loading);
  console.log('activeapge', activePage);
  console.log('user role', user.role);
  return (
    // <Router>
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <div className="page">
        <div className="flex-fill">
          <TopBar
            activePage={activePage}
            setActivePage={setActivePage}
            createAccess={fetchCreateAccessRes.result.length != 0}
            updateAccess={fetchModAccessRes.result.length != 0}
            approvalAccess={fetchApprovalAccessRes.result.length != 0}
            adminAccess={fetchAdminAccessRes.result.length != 0}
            lang={lang}
            changeLang={changeLang}
            translations={fetchTranslationsRes.result}
          />
          <div className="my-3 my-md-5">
            <div className="container text-left">
              <div className="row">
                <div className="col-12">
                  <Modal show={showModal} centered size="sm">
                    <Modal.Body>
                      <Row>
                        <div className="col-auto">
                          <Spinner animation="border" variant="warning" />
                        </div>
                        <div className="text-left">
                          <span className="align-middle">&nbsp;Please wait</span>
                        </div>
                      </Row>
                    </Modal.Body>
                  </Modal>
                  {['Cr Manager', 'Second Cr Manager', 'Risk Advisor'].indexOf(user.role) >= 0 && (
                    <UpdateModal
                      show={confirmationModal === 'approve'}
                      setActivePage={setActivePage}
                      requestId={confirmationId}
                      requestor={accounts.length > 0 ? accounts[0].username : ''}
                      onClose={() => {
                        setConfirmationModal('');
                        setConfirmationId('');
                        // window.location.reload();
                      }}
                      data={AllRequestViewData.result}
                      removeRequest={() => {
                        let tmp = approvalData;
                        tmp.splice(CrData, 1);
                        setApprovalData(tmp);
                      }}
                    />
                  )}
                  {!(
                    confirmationModal === 'approve' &&
                    ['Cr Manager', 'Second Cr Manager', 'Risk Advisor'].indexOf(user.role) >= 0
                  ) && (
                    <ConfirmationModal
                      show={confirmationModal}
                      type={confirmationModal}
                      setActivePage={setActivePage}
                      requestId={confirmationId}
                      requestor={accounts.length > 0 ? accounts[0].username : ''}
                      onClose={() => {
                        setConfirmationModal('');
                        setConfirmationId('');
                      }}
                      removeRequest={() => {
                        let tmp = approvalData;
                        tmp.splice(CrData, 1);
                        setApprovalData(tmp);
                      }}
                    >
                      Are you sure you want to <strong> {confirmationModal} </strong> this request?
                    </ConfirmationModal>
                  )}
                  {confirmationModal === 'approve' &&
                    user.country == 'Netherlands' &&
                    ['CX'].indexOf(user.role) >= 0 && (
                      <ConfirmationModal
                        show={confirmationModal}
                        type={confirmationModal}
                        requestId={confirmationId}
                        setActivePage={setActivePage}
                        requestor={accounts.length > 0 ? accounts[0].username : ''}
                        onClose={() => {
                          setConfirmationModal('');
                          setConfirmationId('');
                        }}
                        removeRequest={() => {
                          let tmp = approvalData;
                          tmp.splice(CrData, 1);
                          setApprovalData(tmp);
                        }}
                      >
                        Are you sure you want to <strong> {confirmationModal} </strong> this
                        request?
                      </ConfirmationModal>
                    )}
                  <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route
                      exact
                      path="/"
                      element={
                        !localStorage.getItem('id_token') ? (
                          // Display a spinner while waiting for `id_token` to initialize
                          <div className="spinner-container">
                            <Spinner /> {/* Replace this with your spinner component */}
                          </div>
                        ) : localStorage.getItem('Role') !== 'undefined' ? (
                          <Home
                            accounts={accounts}
                            openRequest={openRequest}
                            RequestCreationType={RequestCreationType}
                            translations={fetchTranslationsRes.result}
                          />
                        ) : (
                          <Navigate to="/noAccess" />
                        )
                      }
                    />
                    <Route
                      path="/create/general"
                      element={
                        <CreateCustomer
                          createNew
                          accounts={accounts}
                          openRequest={openRequest}
                          data={fetchTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                        />
                      }
                    />
                    <Route
                      path="/create/internal"
                      element={
                        <CreateInternal
                          createNew
                          accounts={accounts}
                          data={fetchTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                        />
                      }
                    />
                    {/* <Route path="/create/extension" element={<CustomerExtension createNew accounts={accounts} data={fetchTicketDataRes.result}  translations={fetchTranslationsRes.result}/>}/>
                  <Route path="/create/nlcustomer" element={<CreateNLCustomer  createNew accounts={accounts} data={fetchTicketDataRes.result} translations={fetchTranslationsRes.result} />}/>
                  <Route path="/create/intercompany" element={<CreateInterCompanyCustomer createNew accounts={accounts} data={fetchTicketDataRes.result} translations={fetchTranslationsRes.result} />}/> */}
                    <Route
                      path="/create/vendor"
                      element={
                        <CreateVendorCustomer
                          createNew
                          accounts={accounts}
                          data={fetchTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                        />
                      }
                    />
                    <Route
                      path="/create/hierarchy"
                      element={
                        <CreateHierarchy
                          createNew
                          accounts={accounts}
                          data={fetchTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                        />
                      }
                    />
                    <Route
                      path="/create/plant"
                      element={
                        <CreatePlant
                          createNew
                          accounts={accounts}
                          data={fetchTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                        />
                      }
                    />
                    {/* <Route path="/create/forward" element={<CreateForward createNew accounts={accounts} data={fetchTicketDataRes.result} translations={fetchTranslationsRes.result}/>}/> */}
                    <Route
                      path="/modify"
                      element={
                        <UpdateCustomer
                          createNew
                          accounts={accounts}
                          data={fetchTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                        />
                      }
                    />
                    <Route
                      path="/approvals"
                      element={
                        localStorage.getItem('Role') === 'CX Approver' ||
                        localStorage.getItem('Role') === 'Process Owner' ||
                        localStorage.getItem('Role') === 'Credit level approver' ? (
                          <Approvals
                            review={{ onApprove, onReject }}
                            data={approvalData}
                            setData={(val) => setApprovalData(val)}
                            setCrData={(val) => setCrData(val)}
                            RequestCreationType={RequestCreationType}
                          />
                        ) : (
                          <Navigate to="/noAccess" />
                        )
                      }
                    />
                    <Route
                      path="/allRequests"
                      element={
                        localStorage.Role == 'Process Owner' ||
                        localStorage.Role == 'SPOC' ||
                        localStorage.Role == 'CX Approver' ||
                        localStorage.Role == 'Credit level approver' ? (
                          <AllTickets
                            accounts={accounts}
                            openRequest={openRequest}
                            RequestCreationType={RequestCreationType}
                            translations={fetchTranslationsRes.result}
                          />
                        ) : (
                          <Navigate to="/noAccess" />
                        )
                      }
                    />
                    <Route path="/noAccess" element={<NoAccess />} />
                    <Route
                      path="/manage/access"
                      element={
                        localStorage.Role == 'Process Owner' ? (
                          <AccessPage />
                        ) : (
                          <Navigate to="/noAccess" />
                        )
                      }
                    />
                    <Route path="/contactus" element={<ContactUs />} />
                    <Route
                      path="/review/:ticketNum"
                      element={
                        fetchReviewTicketDataRes.result ? (
                          <ApprovalReview
                            review={{ onApprove, onReject }}
                            data={fetchReviewTicketDataRes.result}
                            translations={fetchTranslationsRes.result}
                            openRequest={openRequest}
                          />
                        ) : (
                          <Navigate to="/noAccess" />
                        )
                      }
                    />
                    <Route
                      path="/request/:ticketNum"
                      element={
                        <ApprovalReview
                          data={fetchReviewTicketDataRes.result}
                          translations={fetchTranslationsRes.result}
                          openRequest={openRequest}
                        />
                      }
                    />
                  </Routes>
                  {/* <Approvals
                    review={{ onApprove, onReject }}
                    data={approvalData}
                    setData={(val) => setApprovalData(val)}
                    setCrData={(val) => setCrData(val)}
                    RequestCreationType={RequestCreationType}
                  /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MsalAuthenticationTemplate>
    // </Router>
  );
};

export default Page;
